.img-bg #offline {
  text-align: center;
  font-weight: 600;
}
.img-bg .Container {
  background-color: azure !important;
}
.img-bg .card {
  border: 4px solid #1b3e8f;
  padding: 30px 30px;
  border-width: 4px 0px;
  width: fit-content;
}
/* .Parent {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100vh;
} */
.img-bg .btn-success {
  color: #fff;
  background-color: #3e3935;
  border-color: #3e3935;
}
.img-bg .btn-success:hover {
  color: #fff;
  background-color: #1b3e8f;
  border-color: #1b3e8f;
}
.img-bg ::marker {
  color: #1b3e8f;
}
.img-bg li#des {
  font-weight: 500;
  color: #1b3e8f;
}
.img-bg span#siteName {
  font-weight: 600;
}
/* span#cont {
color: #1b3e8f;
} */
.img-bg h6.text-left.mt-4.font-weight-bold.mb-0 {
  font-weight: 700;
}

.no-net {
  min-height: calc(100vh - 51px);
  background: #f7f8fa;
}
.no-net.img-bg .card,
.no-net.img-bg .card-header {
  background-color: #fff !important;
}
.no-net.img-bg h6.text-left.mt-4.font-weight-bold.mb-0 {
  color: #000 !important;
}
